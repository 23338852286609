var qrfDom = new q_DOM("q_loading");
qrfDom.addVars({
    show:false,
    hdl:null,
});
qrfDom.public={};
qrfDom.public.eves={
    show:()=>{
        console.log("loading show");
        qrfDom.set("show",true);
        if(qrfDom.vars.hdl)clearTimeout(qrfDom.vars.hdl);
        qrfDom.vars.hdl=setTimeout(function(){
            console.log("loading end auto");
            qrfDom.vars.hdl = null;
            qrfDom.set("show",false);
        },15000);
    },
    close:()=>{
        console.log("loading end");
        let hdl = qrfDom.vars.hdl;
        if(hdl)clearTimeout(hdl);
        qrfDom.vars.hdl = null;
        qrfDom.set("show",false);
    }
}

var html = `
<div>
<div qrf-if='show' style="position:fixed;z-index:999;width:100%;height:100%;background: rgba(0,0,0,0.5);"><img style="position:absolute;width:40px;height:40px;left:calc(50% - 20px);top:calc(50% - 20px);" src="./module/common/images/loading.gif" /></div>
</div>`;
qrfDom.render(html);